
$color2: #f4f4f4;
$color3: #fcfcfc;
$color1: #e6e6e6;

body1 {
    background-color: $color1;
    background-image: linear-gradient(30deg, $color2 12%, transparent 12.5%, transparent 87%, $color2 87.5%, $color2),
    linear-gradient(150deg, $color2 12%, transparent 12.5%, transparent 87%, $color2 87.5%, $color2),
    linear-gradient(30deg, $color2 12%, transparent 12.5%, transparent 87%, $color2 87.5%, $color2),
    linear-gradient(150deg, $color2 12%, transparent 12.5%, transparent 87%, $color2 87.5%, $color2),
    linear-gradient(60deg, $color3 25%, transparent 25.5%, transparent 75%, $color3 75%, $color3), 
    linear-gradient(60deg, $color3 25%, transparent 25.5%, transparent 75%, $color3 75%, $color3);
    background-size:40px 70px;
    background-position: 0 0, 0 0, 20px 35px, 20px 35px, 0 0, 20px 35px;
}


@keyframes slide {
    from {
        background-position: 0 0;
    }

    to {
        background-position: -120px 60px;
    }
}

body1 {
    background-color: #ffec63;
    background-image: linear-gradient(
        45deg,
        #ffd966 25%,
        transparent 25%,
        transparent 75%,
        #ffd966 75%,
        #ffd966
        ),
        linear-gradient(
        -45deg,
        #ffd966 25%,
        transparent 25%,
        transparent 75%,
        #ffd966 75%,
        #ffd966
        );
    background-size: 60px 60px;
    background-position: 0 0;
    animation: slide 4s infinite linear;
}

$color1: #d0e2dc;
$color2: #f3f7e7;

body {
    background:
linear-gradient(45deg, $color1 45px, transparent 45px)64px 64px,
linear-gradient(45deg, $color1 45px, transparent 45px,transparent 91px, $color2 91px, $color2 135px, transparent 135px),
linear-gradient(-45deg, $color1 23px, transparent 23px, transparent 68px,$color1 68px,$color1 113px,transparent 113px,transparent 158px,$color1 158px);
background-color:$color2;
background-size: 128px 128px;
}