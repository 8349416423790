@import url("../node_modules/bootstrap/dist/css/bootstrap.min.css");
@import url('./style.scss');

.code-font {
    font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
    font-size: 0.8em;
    display: inline-block;
    background: #e6e6e6;
    padding: 0 5px;
    margin: 0 5px;
    border-radius: 4px;
}



$grayBorder: #00000042;

body{
    font-family: 'Patrick Hand SC', cursive;
    font-variant-ligatures: no-common-ligatures;
    color:black;
    overflow-x: hidden;
}
h1{
    font-size: 4em;
    margin-top: 50px;
}
.h2, h2 {
    margin-top: 20px;
}
h4 {
    margin-top: 20px;
}
.current-level {
    font-size: 1.8em;
    margin-right: 25px;
}
.board-wrapper {
    position: relative;
}
.board-wrapper svg {
    position: absolute;
    top: 0;
    left: 0;
}
.board {
    width: 232px;
}

.board .memory-cell 
{
    border: solid 1px black;
    width: 58px;
    height:58px;
    float: left;
    position: relative;
    padding: 4px;
    background-color: white;
}
.board .memory-cell-value
{
    display: block;
    top: 18px;
}

.memory-cell-name {
    font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
}

.fake-cell .memory-cell-value
{    
    position: absolute;
    top: 0;
    left: 2px;
    font-size: 0.8em;
}
.fake-cell .memory-cell-name
{    
    position: absolute;
    bottom: 0;
    left: 2px;
    font-size: 0.8em;
}

.computation-error {
    font-size: 1.2em;
    background: #ffe7e7;
    padding: 10px;
    border-radius: 5px;
}
.you-did-it
{
    font-size: 1.3em;
    background: #c0ffc5;
    padding: 5px 8px;
    border-radius: 7px;
    display: block;
}
.clear
{
    clear: both;
}
.editor-wrapper
{
    position: relative;
    height: calc(100vh - 70px);
    border-right: 2px solid $grayBorder;
}
.boards-wrapper
{
    padding-left: 50px;
}

#editor {
    margin: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

$blueCell: #7171ff;
$redCell: #ff4141;
$pinkCell: #ff69d4;
$greenICell: #1dd648;
$greenOCell: #155508;

.green-o, .board .memory-cell.green-o
{
    background-color: $greenOCell;
    color: white
}
.green-i, .board .memory-cell.green-i
{
    background-color: $greenICell;
    color: white
}

.blue, .board .memory-cell.blue
{
    background-color: $blueCell;
    color: white
}
.red, .board .memory-cell.red
{
    background-color: $redCell;
    color: white
}
.pink, .board .memory-cell.pink
{
    background-color: $pinkCell;
    color: white
}

.blue.red, .board .memory-cell.blue.red
{
    background: linear-gradient(-45deg, $blueCell 50%, $redCell 50%, $redCell);
}
.blue.pink, .board .memory-cell.blue.pink
{
    background: linear-gradient(-45deg, $blueCell 50%, $pinkCell 50%, $pinkCell);
}

.ace_marker-layer .readonly
{
    background: #ffc3c3;
}

button.hand{
    align-self:center;
    background:white;
    padding: 0.2rem 1.5rem 2rem 1.5rem;
    margin:0 1rem;
    transition:all .4s ease;
    color:#41403E;
    font-size:2rem;
    letter-spacing:1px;
    outline:none;
    box-shadow: 10px 19px 17px -13px hsla(0,0%,0%,.3);
    border-radius: 255px 15px 225px 15px/15px 225px 15px 255px;
    /*
    Above is shorthand for:
    border-top-left-radius: 255px 15px;
    border-top-right-radius: 15px 225px;
    border-bottom-right-radius: 225px 15px;
    border-bottom-left-radius:15px 255px;
    */
}
button.hand:hover{
    box-shadow:2px 8px 4px -6px hsla(0,0%,0%,.3);
} 
button.hand.lined.thick{
    border:solid 7px #41403E;
}
button.hand.dotted.thick{
    border:dotted 5px #41403E;
}
button.hand.dashed.thick{
    border:dashed 5px #41403E;
}
button.hand.lined.thin{
    border:solid 2px #41403E;
}
button.hand.dotted.thin{
    border:dotted 2px #41403E;
}
button.hand.dashed.thin{
    border:dashed 2px #41403E;
}

button.hand.disabled {
    color: #b1b1b1;
}

button.hand.disabled{
    box-shadow:none;
} 
button.hand.disabled:hover{
    box-shadow:none;
} 

.level-selector-wrapper
{
    margin-top:50px; 
}
.header-level-nav
{
    background-color: #00000033;
    padding: 5px 0px;
    border-bottom: 2px solid $grayBorder;
}

.header-level-nav button {
    font-size: 18px;
    padding: 5px 15px;
}
.level-selector-wrapper .row {
    margin: 45px 0;
}


span.fake-cell {
    position: relative;
    display: inline-block;
    padding: 5px;
    border:solid 1px black;
    margin:0px 5px;
    min-width: 40px;
    min-height: 40px;
}


.initial-memory button
{
    margin-top: 10px;
    margin-bottom: -10px;
}

.row.header button{
    margin-right: 45px;
}

.level-button
{
    position: relative;
}
.bonus-challenges
{
    margin-top:40px;
}
.exp-challenge
{
    display: inline-block;
    height: 37px;
    width: 37px;
}
.mem-challenge
{
    display: inline-block;
    height: 37px;
    width: 37px;
}
.level-button .exp-challenge
{
    position: absolute;
    bottom: 7px;
    display: block;
    right: 37px;
}
.level-button .mem-challenge
{
    position: absolute;
    bottom: 7px;
    display: block;
    right: 0px;
}


/*  MODAL */


  
.fancy-modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    max-height:90%;
    overflow-y: scroll;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    padding: 1.6rem 3rem;
    border: 3px solid black;
    border-radius: 5px;
    background: white;
    box-shadow: 8px 8px 0 rgba(0, 0, 0, 0.2);
    z-index: 99999;
  }
.fancy-modal  .message {
    font-size:1.3rem;
    margin-bottom: 1.6rem;
    margin-top: 0;
  }
.fancy-button {
    color:inherit;
    font-family:inherit;
    font-size: inherit;
    background: white;
    padding: 0.3rem 3.4rem;
    border: 3px solid black;
    margin-right: 2.6rem;
    box-shadow: 0 0 0 black;
    transition: all 0.2s;
}

.fancy-modal .fancy-button:last-child {
    margin: 0;
}

.fancy-button:hover {
    box-shadow: 0.4rem 0.4rem 0 black;
    transform: translate(-0.4rem, -0.4rem);
}

.fancy-button:active {
    box-shadow: 0 0 0 black;
    transform: translate(0, 0);
}

.fancy-modal  .options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.readonly-indicator
{
    position: absolute;
    height: 15px;
    top: 0;
    right: 0;
    width: 15px;
    background: #9a0000;
    display: block;
    font-size: 1em;
    line-height: 8px;
    padding-left: 4px;
    color:white;
}

/* width */
::-webkit-scrollbar {
    width: 20px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(255, 255, 255); 
    border: 3px solid black
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(206, 206, 206); 
  }